import React from "react";
import { handleAuthentication } from "../components/utils/auth";

const Callback = () => {
  handleAuthentication();

  return <div>test</div>;
};

export default Callback;
